import React from "react"
import styled from "styled-components"

const HalfWrapper = styled.div`
  width: 50%;
  height: ${(props) => props.theme.height};
  padding: ${(props) => props.theme.padding};
  display: inline-block;
  box-sizing: border-box;
  & h1 {
    color: ${(props) => props.theme.h1color};
  }
  & h4 {
    color: ${(props) => props.theme.h4color};
    margin: 15px;
  }
  & a {
    color: ${(props) => props.theme.acolor};
    text-decoration: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: ${(props) => props.mobile.height};
  }
`

HalfWrapper.defaultProps = {
  theme: {
    height: "100%",
    padding: "5%",
    h1color: "white",
    h4color: "white",
    acolor: "white",
  },
  mobile: {
    height: "50%",
  },
}

export default HalfWrapper
