import React from "react"
import styled from "styled-components"

const TitleWrapper = styled.h1`
  color: ${(props) => props.style.color};
`

TitleWrapper.defaultProps = {
  style: {
    color: "black",
  },
}

const Title = ({ style, mobileStyle, children }) => {
  return <TitleWrapper style={style}>{children}</TitleWrapper>
}

export default Title
