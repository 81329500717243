/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { Typography, Grid } from '@material-ui/core'
import { Link } from 'gatsby-theme-material-ui'

import Layout from '../components/layout'
import Title from '../components/title'
import ContactForm from '../components/contactForm'
import HalfWrapper from '../components/halfWrapper'
import GoogleMapComponentWithMarker from '../components/map'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationArrow,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

const KontaktPage = ({ data }) => {
  const image = data.allFile.nodes[0]

  return (
    <Layout titleImage={image} fixed title="Kontakt">
      <Grid container>
        <Grid item container alignItems="center" justify="center" md={6}>
          <Grid item>
            <Typography variant="h3" color="primary">
              Kontakt
            </Typography>
            {/* <Typography variant="h6">
              <FontAwesomeIcon icon={faLocationArrow} />{' '}
              {'\t 43000 Bjelovar, J.J.Strossmayera 9'}
            </Typography> */}
            <Typography variant="h6">
              <Link href="tel:0038543241242" color="inherit">
                <FontAwesomeIcon icon={faPhone} /> {'\t 043/241-242'}
              </Link>
            </Typography>
            <Typography variant="h6">
              <Link href="mailto:gloria@bj.t-com.hr" color="inherit">
                <FontAwesomeIcon icon={faEnvelope} /> {'\t gloria@bj.t-com.hr'}
              </Link>
            </Typography>

            <Typography variant="h6">
              <Link
                href="https://www.facebook.com/gloriabjelovar/"
                color="inherit"
              >
                <FontAwesomeIcon icon={faFacebook} /> {'\t Facebook'}
              </Link>
            </Typography>
            <Typography variant="h6">
              <Link
                href="https://www.instagram.com/gloriabjelovar/"
                color="inherit"
              >
                <FontAwesomeIcon icon={faInstagram} /> {'\t Instagram'}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <ContactForm action="https://www.flexyform.com/f/fc3862c1b934bf5a861eacbcd59027faed0d36b4"></ContactForm>
        </Grid>
      </Grid>

      {/* <Typography variant="h3" color="primary">
        Kako do nas
      </Typography> */}
      <Grid container>
        <Grid item md={6}>
          <GoogleMapComponentWithMarker
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCrw3DozzgAmMcO1Ol6VhdGZ6bmKmsZs70"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{ height: `100%`, minHeight: '300px', width: '100%' }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            zoom={14}
            url={'https://goo.gl/maps/EuAP9P5S3eW8irL68'}
            location={{
              lat: 45.8982545, // latitude to position the marker
              lng: 16.844672, // longitude to position the marker
            }}
          />
        </Grid>
        <Grid item container alignItems="center" justify="center" md={6}>
          <Grid item>
            <Typography variant="h6">
              <FontAwesomeIcon icon={faLocationArrow} />{' '}
              {'\t 43000 Bjelovar, J.J.Strossmayera 9'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}
export const query = graphql`
  query {
    allFile(filter: { relativePath: { eq: "gloria/hodnik_2.jpg" } }) {
      nodes {
        childImageSharp {
          fluid(
            maxWidth: 1200
            srcSetBreakpoints: [200, 340, 480, 900]
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
            originalName
            aspectRatio
          }
        }
      }
    }
  }
`

export default KontaktPage
